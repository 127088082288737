import React from "react";
import Api from "../../Service/Api";
import Header from "../../pageComponents/header";
import Banner from "../../pageComponents/banner";
import bg from "../../assets/image/forumsBg.png";
import Footer from "../../pageComponents/footer";
import ForumsEl from "../../Components/forumsEl";
import Projects from "../../pageComponents/projects";
import Partners from "../../pageComponents/partners";
import Organizer from "../../pageComponents/organizers";
import Button from 'Components/button';

export default class Forums extends React.Component {
    Api = new Api()

    state = {
        user: undefined,
        forums: undefined,
        showAllPartners: false,
        showAllProjects: false
    }

    componentDidMount() {
        this.getUserData();
        this.getAllForum();

    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }

    getAllForum = () => {
        this.Api.getAllForums()
            .then(forums => this.setState({forums}))
            .catch(e => console.log('error', e))
    }

    render() {
        const {user, forums, showAllPartners, showAllProjects} = this.state
        return (
            <div className="forums__page page">
                <Header className={'header__orange'} user={user} getUserData={this.getUserData}/>
                <Banner
                    bg={bg}
                    banner__head={`Окружные форумы добровольцев 2020`}
                    banner__text={`Это уникальная цифровая площадка, работающаяв режиме нон-стоп, где лучшие спикеры страны, эксперты в разных областях, от предпринимательства и менеджмента до искусства и спорта, делятся своими знаниями и опытом. Каждую неделю вас ждут яркие выступления лидеров мнений, панельные дискуссии, конкурсы, программы обучений и тематические онлайн-сцены.`}
                    banner__btn={!user ? `Регистрация` : undefined}

                />
                <div className="cnt">
                    <div className="forums__all">

                        {forums && Object.values(forums).map((forum, index) => {
                            return (
                                <div
                                    key={forum.id + +new Date() + index}
                                    className={'forums__el'}
                                >
                                    <ForumsEl
                                        img={'https://dobroinrussia.online/uploads' + forum.image}
                                        forum={forum}
                                        name={forum.name}
                                        dateStart={forum.date_start}
                                        dateEnd={forum.date_end}
                                        showBtn={user === false}
                                    />
                                </div>
                            )
                        })}
                    </div>

                    <div className={"projects__all "  + (showAllProjects && 'show')}>
                        <Projects showDesc={'true'}/>
                        {!showAllProjects &&
                            <div className="partners__btns">
                                <Button
                                    className={'button__orange'}
                                    onClick={() => {
                                        this.setState({showAllProjects: true})
                                    }}
                                    text={'Смотреть все'}
                                />
                            </div>
                        }
                    </div>

                    <div className={"partners__all " + (showAllPartners && 'show')}>
                        <Partners showDesc={'true'}/>
                        {!showAllPartners &&
                            <div className="partners__btns">
                                <Button
                                    className={'button__orange'}
                                    onClick={() => {
                                        this.setState({showAllPartners: true})
                                    }}
                                    text={'Смотреть все'}
                                />
                            </div>
                        }
                    </div>
                    <div className="organizer_all">
                        <Organizer showDesc={'true'}/>
                    </div>
                </div>
                <Footer/>

            </div>
        )
    }
}