import React, {useState} from "react";
import ListEl from './_list-el'
import Moment from "react-moment";
import moment from "moment";

export default ({user, day, dayName, timeZone, index, forumDaysEvent, forumDaysEventSpeakers, serverTime, userEvents, getForumData,forum_id, showPopapChange, translate, lang}) => {
    let time = timeZone;
    let serverTimeMoment = moment(serverTime);
    // let serverTimeMoment = moment('2020-09-23' + ' 00:00:01');
    let EndMomemnt = moment(day.date + ' 00:00:00');
    let diffEnd = serverTimeMoment.diff(EndMomemnt, 'days')
    const [showList, setShowList] = useState((index == 1 && diffEnd == 1) ? 0 : 1);
    return (
        <div
            className={'programs__list'}
        >
            {/*{time ?
                <div className="programs__list-tz">Время указано по часовому поясу МСК {time > 0 ? '+' : '-'} {Math.abs(time)} {time === 1 || time === -1 ? 'час' : 'часа' }</div>
                :
                <div className="programs__list-tz">Время указано по часовому поясу МСК </div>
            }*/}
            <div className="programs__list-head">
                <div className="programs__list-date">
                    <div className="left">
                        {index} {translate.program.day[lang]} (<Moment locale={lang} format="dddd">{day.date}</Moment>)
                        {time ?
                            <div className="programs__list-tz">GMT {time > 0 ? '+' : '-'} {Math.abs(time)}</div>
                            :
                            <div className="programs__list-tz">GMT </div>
                        }
                    </div>
                    <div
                        className={"programs__list-arrow " + (showList && 'active')}
                        onClick={() => setShowList(!showList)}
                    />
                </div>
                {/*<div className="programs__list-track">*/}
                {/*    <div className="track__el track__el-red">Трек «Я»</div>*/}
                {/*    <div className="track__el track__el-blue">Трек «Движение»</div>*/}
                {/*    <div className="track__el track__el-green">Трек «Регион»</div>*/}
                {/*</div>*/}
            </div>

            {forumDaysEvent.map((event, index) => {
                return (
                    <ListEl
                        key={event.id + +new Date()}
                        forum_id={forum_id}
                        user={user}
                        day={day}
                        serverTime={serverTime}
                        event={event}
                        speakers={forumDaysEventSpeakers[event.id]}
                        userEvents={userEvents}
                        getForumData={getForumData}
                        showPopapChange={showPopapChange}
                        showList={showList}
                        translate={translate}
                        lang={lang}
                    />)

            })}

        </div>

    )
}
