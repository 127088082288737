import React from 'react'
import MainPage from "Page/Main";
import ForumDayEventsPage from "Page/forumDayEvents";
import PartnersPage from "Page/partners";
import ProjectPage from "Page/project";
import ForumsPage from "Page/forums";
import ProfilePage from "Page/profile/vmeste";
import TrainingPage from "Page/training";
import LessonPage from "Page/lesson";
import TestPage from "Page/test";
import TestOnePage from "Page/testOne";
import Login from "../Page/login";
import { Switch, Route } from 'react-router-dom'

export default class Main extends React.Component{
    render() {
        return (
            <Switch>
                <Route exact path='/' component={ForumDayEventsPage}/>
                <Route path='/forum-day-events/:id' component={ForumDayEventsPage}/>
                <Route path='/partners/:id' component={PartnersPage}/>
                <Route path='/project/:id' component={ProjectPage}/>
                <Route path='/forums' component={ForumsPage}/>
                <Route path='/profile' component={ProfilePage}/>
                <Route path='/training' component={TrainingPage}/>
                <Route path='/lesson/:id' component={LessonPage}/>
                <Route path='/test' component={TestPage}/>
                <Route path='/testOne' component={TestOnePage}/>
                <Route path='/login' component={Login}/>
            </Switch>
        )
    }
}
