import React from "react";
import Api from "../../Service/Api";
import { Link } from "react-router-dom";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import ava from 'assets/image/ava.png'

class Account extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    Api = new Api();


    render() {
        const {user, className} = this.props
        return (
            <div className="header__account">
                {user !== undefined &&
                (user === false ?
                        <a href={'/login'}
                           className="header__account-link">
                            <span className="header__account-text">Войти</span>
                            {/*{className === "header__ed"*/}
                            {/*    ?*/}
                            {/*    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*        <path d="M16 21.5H0V19.5C0 18.1739 0.526784 16.9021 1.46447 15.9645C2.40215 15.0268 3.67392 14.5 5 14.5H11C12.3261 14.5 13.5979 15.0268 14.5355 15.9645C15.4732 16.9021 16 18.1739 16 19.5V21.5ZM8 12.5C7.21207 12.5 6.43185 12.3448 5.7039 12.0433C4.97595 11.7417 4.31451 11.2998 3.75736 10.7426C3.20021 10.1855 2.75825 9.52405 2.45672 8.7961C2.15519 8.06815 2 7.28793 2 6.5C2 5.71207 2.15519 4.93185 2.45672 4.2039C2.75825 3.47595 3.20021 2.81451 3.75736 2.25736C4.31451 1.70021 4.97595 1.25825 5.7039 0.956723C6.43185 0.655195 7.21207 0.5 8 0.5C9.5913 0.5 11.1174 1.13214 12.2426 2.25736C13.3679 3.38258 14 4.9087 14 6.5C14 8.0913 13.3679 9.61742 12.2426 10.7426C11.1174 11.8679 9.5913 12.5 8 12.5Z" fill="white"/>*/}
                            {/*    </svg>*/}
                            {/*    :*/}
                            {/*    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*        <circle cx="20" cy="20" r="20" fill="white"/>*/}
                            {/*        <path d="M28 27V25C28 22.7909 26.2091 21 24 21H16C13.7909 21 12 22.7909 12 25V27" stroke="#FF6700" strokeWidth="2"/>*/}
                            {/*        <circle cx="20" cy="13" r="4" stroke="#FF6700" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                            {/*    </svg>*/}
                            {/*}*/}
                            <div className="header__account-logo">
                                <svg width="100%" height="100%" id="_Слой_2" data-name="Слой 2" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 92.61 76.8">
                                    <g id="_Слой_1-2" data-name="Слой 1">
                                        <rect className="cls-2" width="92.61" height="76.8" rx="11.71" ry="11.71" fill="#cc0001"/>
                                        <path className="cls-1" fill="#fff"
                                              d="M37.15,39.27c-6.08-6.19-4.1-14.67,.64-18.72,4.98-4.25,12.31-4.16,17.14,.26,4.54,4.16,6.23,12.08,.54,18.26,.16,.08,.32,.17,.47,.24,5.99,2.83,10.09,7.37,12.27,13.64,.09,.27,.22,.52,.34,.78,.43,.98,.36,1.91-.3,2.75-.65,.82-1.53,1.15-2.55,.95-1.04-.21-1.77-.85-2.02-1.89-.81-3.36-2.56-6.15-5.07-8.46-3.79-3.49-8.3-5.02-13.42-4.7-7.08,.44-13.5,5.52-15.6,12.3-.06,.19-.09,.39-.13,.59-.2,1.1-.83,1.82-1.9,2.11-1.05,.29-1.97-.01-2.67-.84-.72-.85-.79-1.81-.37-2.83,.45-1.1,.82-2.24,1.33-3.31,2.38-4.97,6.06-8.64,11.04-11,.08-.04,.15-.08,.26-.14Zm1.23-8.87c0,4.41,3.53,7.98,7.93,8,4.39,.02,7.98-3.56,7.99-7.95,0-4.41-3.53-7.98-7.94-8-4.38-.03-7.97,3.55-7.98,7.95Z"/>
                                    </g>
                                </svg>
                            </div>
                        </a>
                        :
                        <Link to={'/profile'}
                            className="header__account-link">
                            <span className="header__account-text">
                                    {user.name} {user.surname} <br/>
                            </span>
                            <div className="header__account-logo">
                                {user.image !== null ? <img className="header__avatar" src={user.image} alt="Аватар"/> : <img className="header__avatar" src={ava} alt="Аватар"/>}
                            </div>

                        </Link>
                )
                }
            </div>

        )
    }
}

export default withCookies(Account)