import React, {useEffect} from 'react';

const Login = ({ history }) => {
    const { Connect, Config, ConnectEvents } = window.SuperAppKit;
    Config.init({
        appId: 51690614,

        appSettings: {
            agreements: '',
            promo: '',
            vkc_behavior: '',
            vkc_auth_action: '',
            vkc_brand: '',
            vkc_display_mode: '',
        },
    });

    const oneTapButton = Connect.buttonOneTapAuth({
        callback: function(e) {
            const type = e.type;
            if (!type) {
                return false;
            }

            switch (type) {
                case ConnectEvents.OneTapAuthEventsSDK.LOGIN_SUCCESS:
                    console.log(e);
                    let hash = JSON.stringify(e.payload)
                    console.log('ok')
                    history.push(`/?payload=${hash}&state=`)
                    return false

                case ConnectEvents.OneTapAuthEventsSDK.FULL_AUTH_NEEDED:
                case ConnectEvents.OneTapAuthEventsSDK.PHONE_VALIDATION_NEEDED:
                case ConnectEvents.ButtonOneTapAuthEventsSDK.SHOW_LOGIN:
                    return Connect.redirectAuth({
                        url: 'https://xn--80akiabgfsmfcnijjdzp5c.xn--p1ai/',
                        state: '123'});

                case ConnectEvents.ButtonOneTapAuthEventsSDK.SHOW_LOGIN_OPTIONS:
                    return Connect.redirectAuth({ screen: 'phone', url: 'https://xn--80akiabgfsmfcnijjdzp5c.xn--p1ai/' });
            }

            return false;
        },
        options: {
            showAlternativeLogin: false,
            showAgreements: true,
            displayMode: 'default',
            langId: 0,
            buttonStyles: {
                borderRadius: 8,
                height: 50,
            },
        },
    });

    useEffect(() => {
        document.querySelector('.login__frame').appendChild(oneTapButton.getFrame());
    }, [])

    return(
        <div className="login">
            <div className="cnt">
                <div className="login__container">
                    <div>
                        <div className="login__row">
                            <div className="login__image">
                                <svg width="100%" id="_Слой_2" data-name="Слой 2" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 300.98 267.94">
                                    <g id="_Слой_1-2" data-name="Слой 1">
                                        <g>
                                            <path fill="#FFF"
                                                  d="M300.61,183.32c-4.38-61.62-58.36-115.67-106.7-152.94,0,0-.02,0-.05,.02-.21-.18-.42-.37-.66-.55C163.91,7.35,152.11,.86,150.49,0c-1.62,.86-13.42,7.35-42.71,29.85-.24,.19-.46,.37-.66,.55-.03-.02-.05-.02-.05-.02C58.74,67.65,4.75,121.7,.38,183.32c-3.2,45.13,14.19,53.61,45.2,84.62,32-41.72,79.06-78.15,104.91-96.74,25.85,18.59,72.91,55.02,104.91,96.74,31.01-31.01,48.4-39.48,45.2-84.62Zm-54.13,14.19c-2.61-2.61-86.45-71.04-95.98-71.98-9.53,.94-93.37,69.37-95.98,71.98-2.63,2.63-47.88-42.53,95.98-140.72,143.86,98.19,98.61,143.34,95.98,140.72Z"/>
                                            <path fill="#3e65af"
                                                  d="M247.68,198.47s-26.88-23.07-61.78-50.84h0c-10.51-8.94-31.01-22.08-35.21-22.08-8.56,0-93.55,69.38-96.18,72-2.63,2.63-47.92-42.57,96.18-140.86,0,0-.28-.2-.78-.54,3.84,2.5,7.75,5.12,11.63,7.81,2.02,1.4,3.9,2.72,5.65,3.95,2.48,1.52,4.89,3.1,7.19,4.77,1.98,1.56,4.05,3.22,6.18,4.94,6.17,4.67,8.54,6.86,9.43,7.8,28.36,23.98,61.08,57.47,64.24,83.22,4.35,21.55-6.55,29.81-6.55,29.81h0Z"/>
                                            <path fill="#ca2026"
                                                  d="M99.71,128.19c-44.67,34.69-45.73,70.43-45.73,70.43,0,0,87.25-70.43,96.7-72.53,6.83-1.52,97.23,72.53,97.23,72.53-5.99-29.94-18.34-46.33-45.73-70.43-20.31-17.87-36.62-26.63-51.51-37.84-15.24,11.04-19.41,13.33-50.98,37.84Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="login__header">
                                Окружной<br/>патриотический<br/>форум
                            </div>
                        </div>
                        <h2 className="login__title">
                            Авторизация
                        </h2>
                        <p className="login__text">
                            Добро пожаловать на Окружной патриотический форум!
                        </p>
                        <div className="login__frame">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Login