import React from "react";
import img from 'assets/image/partnerEl.png'
import Api from "../../Service/Api";
import {Link} from "react-router-dom";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";

// Import Swiper styles
import 'swiper/swiper.scss';
import "swiper/modules/pagination/pagination.scss";

class Organizer extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    Api = new Api()

    state = {
        partners: undefined
    }

    componentDidMount() {
        this.getPartners();
    }

    componentDidUpdate(prevProps) {
        if (this.props.forum_id !== prevProps.forum_id) {
            this.getPartners();
        }
    }

    getPartners = () => {
        this.Api.getAllOrganizer('1', this.props.forum_id)
            .then(partners => this.setState({partners}))
            .catch(e => console.log('error', e))
    }

    render() {
        const {partners} = this.state;
        const {showDesc, forum_id} = this.props;
        return (
            <div className='partners'>
                <div className="cnt">
                    <div className="partners__head" id={'organizers_head'}>
                        Организаторы
                    </div>
                    <div className="partners__flex">
                        <Swiper
                            className="mySwiper"
                            slidesPerView={1}
                            spaceBetween={20}
                            breakpoints={{
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 20,
                                },
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                        >
                            {partners  && Object.values(partners).map(((partner, index) => {
                                return (
                                    <SwiperSlide className="partners__element" key={index}>
                                        <div className="partner_el organizer" key={index}>
                                            {/*<Link to={"/" + (this.props.allCookies.lang || 'ru') + '/partners/' + partner.id} className="partner_el-icon">*/}
                                            <Link to={"#"} className="partner_el-icon">
                                                <div className="partner_el-img">
                                                    <img src={'/uploads' + partner.logo} alt=""/>
                                                </div>
                                            </Link>
                                            {showDesc === 'true' ?
                                                <div className="partner_el-desc">
                                                    <div className="name">{partner.name}</div>
                                                    <div className="description" dangerouslySetInnerHTML={{__html: partner.description}}/>
                                                </div>
                                                :
                                                undefined
                                            }
                                        </div>
                                    </SwiperSlide>
                                )
                            }))}
                        </Swiper>
                    </div>
                </div>
            </div>
        )
    }
}

export default withCookies(Organizer)