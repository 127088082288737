import React from "react";
import {Link} from 'react-router-dom'
import ForumsEl from 'Components/forumsEl'

import Slider from "react-slick";
import Api from "../../Service/Api";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";

class activitySlider extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    Api = new Api()

    state = {
        forums: undefined
    }

    componentDidMount() {
        this.getAllForum();
    }

    getAllForum = () => {
        this.Api.getAllForums()
            .then(forums => this.setState({forums}))
            .catch(e => console.log('error', e))
    }

    render() {
        const {forums} = this.state;
        const {user} = this.props;
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                        dots: false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="activitySlider">
                <div className="cnt">
                    <div className="activitySlider__header">
                        <div className="activitySlider__header-text">
                            Мероприятия
                        </div>
                        <div className="activitySlider__header-link">
                            <Link
                                to={"/" + (this.props.allCookies.lang || 'ru') + '/forums'}
                                className={'button__link'}

                            >
                                Все события
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="cnt cnt__visible">
                    <div className="activitySlider__slider">
                        <Slider {...settings}>
                            {forums && Object.values(forums).map((forum, index) => {
                                return (
                                    <div key={forum.id + +new Date() + index}>
                                        <ForumsEl
                                            img={'https://dobroinrussia.online/uploads' + forum.image}
                                            forum={forum}
                                            name={forum.name}
                                            dateStart={forum.date_start}
                                            dateEnd={forum.date_end}
                                            showBtn={user === false}
                                        />
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}
export default withCookies(activitySlider)