import React from 'react'
import Api from 'Service/Api'

import Header from 'pageComponents/header'
import Footer from 'pageComponents/footer'

import UserData from "pageComponents/userData";
import TimeTable from "pageComponents/timeTable";

import elka from '../../assets/image/vmeste/elka.png'
import translate from "../forumDayEvents/_translate";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import ava from '../../assets/image/vmeste/ava.png'

class Main extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  Api = new Api()

  state = {
    user: undefined,
    userEvents: undefined,
    userPoint: undefined,
    separatedEventsCount: undefined
  }

  componentDidMount() {
    this.getUserData();
    this.getUserEvents();
    this.getUserPoint();
    this.getSeparatedEventsCount();
  }

  getUserData = () => {
    this.Api.getUserData()
      .then(user => {
        if (!user) this.props.history.push('/');
        this.setState({user});

      })
      .catch(e => console.log('error', e))
  }

  getUserEvents = () => {
    this.Api.getUserEvents()
      .then((userEvents) => {
        this.setState({userEvents})
      })
      .catch(e => console.log('error', e))
  }

  getUserPoint = () => {
    this.Api.getUserPoint()
      .then((userPoint) => {
        this.setState({userPoint})
      })
      .catch(e => console.log('error', e))
  }

  getSeparatedEventsCount = () => {
    this.Api.getSeparatedEventsCount()
      .then((separatedEventsCount) => {
        this.setState({separatedEventsCount})
      })
      .catch(e => console.log('error', e))
  }
  logout = () => {
    const {getUserData} = this.props
    this.Api.logout()
      .then(() => {this.props.history.push('/')});
  }

  render() {
    const {user, userEvents, userPoint, separatedEventsCount} = this.state;
    if (!user) return <div/>
    console.log(user)
    return (
      <div className="profile__page page">
        <Header className={'header__ed'} user={user} getUserData={this.getUserData}/> {/*translate={translate} lang={this.props.allCookies.lang || 'ru'} bg={true}*/}
        <div className="profile__info">
          <div className="cnt">
            <div className="ava">
              <img src={user.image || ava} alt="" className={user.image ? 'cover' : 'contain'}/>
            </div>
            <div className="name">
              {user.name} {user.surname}&nbsp;
              <small
                style={{fontSize: '60%', cursor: 'pointer'}}
                onClick={this.logout}
              >
                                    (Выход)
                                </small>
            </div>
          </div>
        </div>
        {userEvents && Object.keys(userEvents).length ?
          <TimeTable userEvents={userEvents} translate={translate} lang={this.props.allCookies.lang || 'ru'}/>
          :
          <div/>
        }

        {/*<div className="cnt">*/}
        {/*  <a href="https://xn--80aanbeohciex.xn--p1ai/" target="_blank" className="elka">*/}
        {/*    <div className="elka__desc">*/}
        {/*      <div className="elka__head">*/}
        {/*        {translate.elka.head[this.props.allCookies.lang || 'ru']}*/}
        {/*      </div>*/}
        {/*      <div className="elka__text">*/}
        {/*        {translate.elka.text[this.props.allCookies.lang || 'ru']}*/}
        {/*      </div>*/}
        {/*      <div className="elka__footer">*/}
        {/*        {translate.elka.footer[this.props.allCookies.lang || 'ru']}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <img src={elka} alt="" className="elka__img"/>*/}
        {/*  </a>*/}
        {/*</div>*/}
        {/*<div className="org">*/}
        {/*  <div className="cnt">*/}
        {/*    <div className="org__head">*/}
        {/*      {translate.Organizers.head[this.props.allCookies.lang || 'ru']}*/}
        {/*    </div>*/}
        {/*    <div className="orgs">*/}
        {/*      <a target='blank' href="https://fadm.gov.ru/" className="org__el"><img src='https://static.tildacdn.com/tild6565-6532-4239-b764-643865303463/rosmolodezh-orange.svg' alt=""/></a>*/}
        {/*      <a target='blank' href="http://www.krskstate.ru/" className="org__el"><img src='/uploads/forumPartners/logo/300a3306ea8568c361c9c6f7732193dc286.jpg' alt=""/></a>*/}
        {/*      <a target='blank' href="https://rospatriotcentr.ru/" className="org__el"><img src='https://thumb.tildacdn.com/tild6365-6661-4464-a662-353131633432/-/resize/130x/-/format/webp/photo.png' alt=""/></a>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <Footer className="footer__ed"/>
      </div>
    )
  }
}

export default withCookies(Main)
