import React, {useState} from "react";

const LessonInteractive = ({answer, content, matches, setAnswerProps}) => {

    return (
        <div>
            <div className={'step__text'} dangerouslySetInnerHTML={{__html: content.content}}/>
            {content.answer_type === 'matching' && _renderMatches(matches, setAnswerProps)}
            {content.answer_type === 'free' && _renderFree(setAnswerProps, answer)}
            {content.answer_type === 'upload' && _renderUpload(setAnswerProps)}
        </div>
    )
}
let matchesOut = [];

const _renderMatches = (matches, setAnswerProps) => {
    console.log(matches)
    let matchesIn = [];
    let matchesOutAll = [];
    matches.map((match, index) => {
        matchesIn.push(match.in.name)
        matchesOut[index] = [];
        match.out.map(out => {
            matchesOut[index].push(out.name)
            matchesOut[index].sort()
            matchesOutAll.push(out.name)
        })
    })
    matchesIn = Array.from(new Set(matchesIn))
    matchesOut = Array.from(new Set(matchesOut))
    matchesOutAll = Array.from(new Set(matchesOutAll))
    const shuffle = (array) => {
        return array.sort(() => Math.random() - 0.5);
    }
    return (
        <div>
            {matchesIn.map(matchIn => {
                return (
                    <div className={'matches'}>
                        <div className="matches__in">
                            {matchIn}
                        </div>
                        <div className="matches__out">
                            {shuffle(matchesOutAll).map(matchOut => {
                            // {(matchesOutAll).map(matchOut => {
                                return (
                                    <label>
                                        <input type='checkbox' className={'checkbox'}
                                               value={matchOut}
                                           onChange={(e) => setAnswer(e, setAnswerProps)}
                                        />
                                        <span className="checkBox"/>
                                        {matchOut}
                                    </label>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const _renderFree = (setAnswerProps, answer) => {
    console.log('_renderFree', answer)
    return (
        <div>
            <textarea 
                className={'step__textArea'}
                value={answer}
                onChange={(e) => {setAnswerProps(e.target.value)}}
            >
            </textarea>
        </div>
    )
}

const _renderUpload = (setAnswerProps) => {
    const [name, setName] = useState('');
    return (
        <div>
            <div className="step__text">
                {name}
            </div>
            <div className={'step__file'}>
                <input type="file"
                       onChange={e => {
                           setName(e.target.files[0].name);
                           getBase64(e.target.files[0], setAnswerProps)
                       }}
                />
                <span className="button button__orange">Загрузить</span>
            </div>
        </div>
    )
}

const getBase64 = (file, setAnswerProps) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        console.log(file.name.split('.')[file.name.split('.').length - 1])
        setAnswerProps(reader.result, file.name.split('.')[file.name.split('.').length - 1])
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

const setAnswer = (e, setAnswerProps) => {
    let matches = document.getElementsByClassName('matches')
    let answer = '';
    let checked = [];
    for(let i=0; i<matches.length; i++){
        answer += matches[i].querySelector('.matches__in').textContent + '-';
        let matches__out = matches[i].getElementsByClassName('matches__out');
        for (let j=0; j<matches__out.length; j++){
            answer += getCheckedCheckBoxes(matches__out[j]).toString() + ';'
            checked[i] = getCheckedCheckBoxes(matches__out[j]).sort()
        }
    }

    console.log('checked', checked)
    setAnswerProps(answer,undefined, checked, matchesOut)
}

const getCheckedCheckBoxes = (el) => {
    let checkboxes = el.getElementsByClassName('checkbox');
    let checkboxesChecked = []; // можно в массиве их хранить, если нужно использовать
    for (let index = 0; index < checkboxes.length; index++) {
        if (checkboxes[index].checked) {
            checkboxesChecked.push(checkboxes[index].value); // положим в массив выбранный
        }
    }
    return checkboxesChecked; // для использования в нужном месте
}

export default LessonInteractive