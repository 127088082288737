import React from "react";
import Api from "../../Service/Api";
import Header from "../../pageComponents/header";
import TestComponent from "../../Components/test";
import Footer from "../../pageComponents/footer";

export default class TestOne extends React.Component {
    Api = new Api()

    state = {
        user: undefined,
    }

    componentDidMount() {
        this.getUserData();

    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }


    render() {
        const {user} = this.state
        let lesson = this.props.location !== undefined ? this.props.location.state : this.props.lesson
        return (
            <div className="training__page page">
                <Header className={'header__orange'} user={user} getUserData={this.getUserData}/>
                <div className="cnt">
                    <TestComponent history={this.props.history} lesson={lesson}/>
                </div>
                <Footer/>

            </div>
        )
    }
}