
export default class Api {
    baseUrl = process.env.NODE_ENV === 'development' ? 'http://patriot-forum.loc/' : '/';

    getResource = async (url, body) => {
        // let pathname = window.location.pathname
        // pathname = pathname.split('/')
        // let lang = (pathname[1] == 'en' || pathname[1] == 'ru') ? pathname[1] + '/' : 'ru/'
        const res = await fetch(`${this.baseUrl}ajax-react/${url}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/javascript, */*; q=0.01',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            // body: JSON.stringify(body)
            body: body !== undefined ? Object.keys(body).map(key => key + '=' + body[key]).join('&') : ''

        })
        return res.json(); // Возвращение промиса
    }

    sendData = async (url, body) => {
        // let pathname = window.location.pathname
        // pathname = pathname.split('/')
        // let lang = (pathname[1] == 'en' || pathname[1] == 'ru') ? pathname[1] + '/' : 'ru/'
        console.log(body)
        const res = await fetch(`${this.baseUrl}ajax-react/${url}`, {
            method: 'POST',
            // body: JSON.stringify(body)
            body: body

        })
        return res.json(); // Возвращение промиса
    }


    // Список курсов с уроками
    getLessonCourses = async () => {
        return await this.getResource('get-lesson-courses')
    }


    // Список курсов с уроками
    getLessonStep = async (lesson_id, order) => {
        return await this.getResource('get-lesson-step', {
            lesson_id, order
        })
    }


    // Подсчет статистики для уроков.
    setLessonStats = async (id, step) => {
        return await this.getResource('set-lesson-stats',{
            id: id,
            step: step,
        })
    }


    // Список курсов с тестами
    getTestCourses = async () => {
        return await this.getResource('get-test-courses')
    }

    startTest = async (lId) => {
        return await this.getResource(`test-step?lId=${lId}`)
    }
    getTestStepForLessonStep = async (lId, lStep) => {
        return await this.getResource(`test-step-for-lesson-step?lId=${lId}&lStep=${lStep}`)
    }
    getTestStepForLessonStepCheck = async (lId, lStep,qId, answers) => {
        return await this.getResource(`test-step-for-lesson-step?lId=${lId}&lStep=${lStep}`, {
            qId: qId,
            answers: [answers]
        })
    }
    checkAnswer = async (lId, qId, answers) => {
        return await this.getResource(`test-step?lId=${lId}`, {
            qId: qId,
            answers: [answers]
        })
    }

    setLessonData = async (id, answer_type, answer, ext) => {
        return await this.getResource(`interactive-step-answer`, {
            id, answer_type, answer, ext
        })
    }



    //////////\\\\\\\\\\\\\
    ///   NEW ACTIONS   \\\
    //////////\\\\\\\\\\\\\


    sendMessage = async (message, chat_id) => {
        return await this.getResource('set-chat-message',{
            message: message,
            chat_id: chat_id
        })
    }
    getMessages = async (chat_id) => {
        return await this.getResource('get-chat-message',{
            chat_id: chat_id
        })
    }
    getChats = async () => {
        return await this.getResource('get-available-chats',{})
    }
    getUsers = async () => {
        return await this.getResource('get-all-users',{})
    }
    createChat = async (users) => {
        return await this.getResource('create-chat',{
            users: users
        })
    }
    getChatBot = async () => {
        return await this.getResource('chat-bot-question',{})
    }

    getQuestionnaires = async (questionnaireId) => {
        return await this.getResource('get-questionnaire',{
            questionnaireId: questionnaireId
        })
    }
    setQuestionnaires = async (questionnaire_id, answers) => {
        return await this.getResource('set-questionnaire',{
            questionnaire_id: questionnaire_id,
            answers: answers
        })
    }

    logout = async () => {
        return await this.getResource('logout')
    }

    getUserData = async () => {
        return  await this.getResource('user-data');
    }

    getAllForums = async () => {
        return await this.getResource('get-forums')
    }

    getAllPartners = async (count, forum_id) => {
        return await this.getResource('get-partners',{forum_id: forum_id})
    }
    getAllOrganizer = async (count, forum_id) => {
        return await this.getResource('get-organizers',{forum_id: forum_id})
    }
    getAllProjects = async (forum_id) => {
        forum_id = forum_id == undefined ?  '' : forum_id
        return await this.getResource('get-projects',{
            forum_id
        })
    }

    getForum = async (id) => {
        return await this.getResource('get-forum-day', {id: id})
    }

    saveEvent = async (id) => {
        return await this.getResource('save-event', {id: id})
    }

    getPartner = async (id) => {
        return await this.getResource('get-partner', {id: id})
    }
    getProject = async (id) => {
        return await this.getResource('get-project', {id: id})
    }

    getUserEvents = async () => {
        return await this.getResource('get-user-events')
    }
    getUserPoint = async () => {
        return await this.getResource('get-user-points')
    }
    getSeparatedEventsCount = async () => {
        return await this.getResource('get-separated-events-count')
    }

    setUserPhoto = async (photo) => {
        return await this.getResource('set-user-photo', {
            photo
        })
    }
    checkUser = async (user) => {
        return await this.sendData('check-user', user)
    }
}

