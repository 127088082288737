import React from "react";
import Button from 'Components/button'
import Moment from "react-moment";
import translate from "../../Page/forumDayEvents/_translate";

export default ({translate, lang, style, className, bg, bgPeople, banner__date_start, banner__date_end, banner__head, banner__text, banner__btn, banner__btn_click, user}) => {
    let start = ''
    let end = ''
    let startMonth = ''
    let endMonth = ''
    const months = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']
    if(banner__date_start) {
        const date = new Date(banner__date_start)

        start = banner__date_start.split('-')[2];
        end = banner__date_end.split('-')[2];
        startMonth = Number(banner__date_start.split('-')[1]) < 10 ? months[(banner__date_start.split('-')[1].slice(-1)) - 1] : months[(banner__date_start.split('-')[1]) - 1];
        // startMonth = banner__date_start.split('-')[1];
        // endMonth = banner__date_end.split('-')[1];
    }
    if (className !== 'banner-ed') {
        return (
            <div className={"banner "} style={style}>
                {/*<div className="cnt">*/}
                    {banner__date_start &&
                    <div className="banner__date">
                        {startMonth === endMonth ?
                            <Moment format="DD">{banner__date_start}</Moment>
                            :
                            <Moment format="DD MMMM">{banner__date_start}</Moment>
                        }
                        -
                        <Moment format="DD MMMM">{banner__date_end}</Moment>
                    </div>
                    }
                    {banner__head &&
                    <div className="banner__head" dangerouslySetInnerHTML={{__html: banner__head}}/>
                    }
                    {/*{banner__text &&*/}
                    {/*<div className="banner__text" dangerouslySetInnerHTML={{__html: banner__text}}/>*/}
                    {/*}*/}
                    {banner__btn &&
                    <Button
                        className={'button__orange button__big'}
                        text={banner__btn}


                    />
                    }
                    {className == 'vmeste' && !user &&
                    <a href={'https://dobro.ru/vacancy/10028168?authorized=true'}
                       className={'banner__btn'}
                    >
                        Регистрация
                    </a>
                    }
                {/*</div>*/}
                {bg &&
                <img src={bg} alt="" className={'banner__bg'}/>
                }
                {bgPeople &&
                <img src={bgPeople} alt="" className={'banner__bgPeople'}/>
                }
            </div>
        )
    }else{
        return (
            <div className={"banner " + className} style={style}>
                {/*<div className="cnt">*/}
                {/*    <div className="banner__head">*/}
                {/*        {translate.banner.head[lang]}*/}
                {/*    </div>*/}
                {/*    <div className="banner__subHead">*/}
                {/*        {translate.banner.subhead[lang]}*/}
                {/*    </div>*/}
                {/*    <div className="banner__text2">*/}
                {/*        {translate.banner.text1[lang]}*/}
                {/*    </div>*/}
                {/*    <div className="banner__text3">*/}
                {/*        {translate.banner.text2[lang]}*/}
                {/*    </div>*/}
                {/*    <div className="banner__text4">*/}
                {/*        {translate.banner.text3[lang]}*/}
                {/*    </div>*/}

                {/*</div>*/}
                {bg &&
                <img src={bg} alt="" className={'banner__bg'}/>
                }
                <div className="banner__content">
                    <div className="banner__date-block">
                        {start}-{end}<br/>
                        {startMonth}
                    </div>
                    <div className="banner__date-box">
                        <div className="banner__date-title">
                            Всероссийский патриотический форум
                        </div>
                        <div className="banner__date-subtitle">
                            {banner__text}
                        </div>
                        <div className="banner__date-text">
                            Всероссийский патриотический форум повысит эффективность взаимодействия всех участников патриотического воспитания на территории Российской Федерации и станет площадкой для развития системы патриотического воспитания детей и молодежи.<br/><br/>
                            Форум традиционно начинает свою работу 9 декабря – в День Героев Отечества.<br/><br/>
                            Форум объединит 600 очных и более 4500 онлайн участников из всех регионов России, среди которых представители региональных центров патриотического воспитания, органов власти, курирующих патриотическое воспитание, лидеры и руководители региональных общественных организаций и региональных отделений всероссийских общественных организаций.
                        </div>
                    </div>
                </div>
                {/*<svg className="banner__wave banner__wave--right" width="232" height="54" viewBox="0 0 232 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M34.0886 30.7502C22.0065 38.1559 13.3469 40.594 0 36.1422C19.7052 58.0049 42.4617 57.5429 58.2526 43.9826C91.3344 15.574 97.3754 19.889 127.868 45.2037C140.973 56.083 154.927 55.3163 171.45 46.8593L151.457 30.7502C95.074 -10.3865 90.759 -9.23583 34.0886 30.7502Z" fill="url(#paint0_linear)"/>
                    <path d="M150.882 30.7502C138.8 38.1559 130.14 40.594 116.793 36.1422C136.498 58.0049 159.255 57.5429 175.046 43.9826C208.127 15.574 214.168 19.889 244.661 45.2037C257.766 56.083 271.72 55.3163 288.243 46.8593L268.25 30.7502C211.867 -10.3865 207.552 -9.23583 150.882 30.7502Z" fill="url(#paint1_linear)"/>
                    <defs>
                        <linearGradient id="paint0_linear" x1="95.6493" y1="0.257147" x2="6.32865" y2="40.3867" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#800608"/>
                            <stop offset="1" stopColor="#CB0001"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear" x1="212.442" y1="0.257147" x2="123.122" y2="40.3867" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#800608"/>
                            <stop offset="1" stopColor="#CB0001"/>
                        </linearGradient>
                    </defs>
                </svg>
                <svg className="banner__wave banner__wave--left" width="236" height="54" viewBox="0 0 236 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M201.911 30.4225C213.993 37.8282 222.653 40.2663 236 35.8146C216.295 57.6773 193.538 57.2153 177.747 43.655C144.666 15.2464 138.625 19.5614 108.132 44.8761C95.0274 55.7553 81.0729 54.9887 64.5503 46.5317L84.5432 30.4225C140.926 -10.7141 145.241 -9.56347 201.911 30.4225Z" fill="url(#paint0_linear)"/>
                    <path d="M85.1185 30.4225C97.2005 37.8282 105.86 40.2663 119.207 35.8146C99.5018 57.6773 76.7454 57.2153 60.9544 43.655C27.8727 15.2464 21.8317 19.5614 -8.66106 44.8761C-21.7656 55.7553 -35.7201 54.9887 -52.2426 46.5317L-32.2498 30.4225C24.133 -10.7141 28.448 -9.56347 85.1185 30.4225Z" fill="url(#paint1_linear)"/>
                    <path d="M-31.6745 30.4225C-19.5925 37.8282 -10.9329 40.2663 2.41406 35.8146C-17.2911 57.6773 -40.0476 57.2153 -55.8386 43.655C-88.9203 15.2464 -94.9613 19.5614 -125.454 44.8761C-138.559 55.7553 -152.513 54.9887 -169.036 46.5317L-149.043 30.4225C-92.66 -10.7141 -88.345 -9.56347 -31.6745 30.4225Z" fill="url(#paint2_linear)"/>
                    <defs>
                        <linearGradient id="paint0_linear" x1="140.351" y1="-0.0704896" x2="229.671" y2="40.0591" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#800608"/>
                            <stop offset="1" stop-color="#CB0001"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear" x1="23.5577" y1="-0.0704896" x2="112.878" y2="40.0591" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#800608"/>
                            <stop offset="1" stop-color="#CB0001"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear" x1="-93.2352" y1="-0.0704896" x2="-3.91459" y2="40.0591" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#800608"/>
                            <stop offset="1" stop-color="#CB0001"/>
                        </linearGradient>
                    </defs>
                </svg>*/}
                {/*{bgPeople &&*/}
                {/*<img src={bgPeople} alt="" className={'banner__bgPeople'}/>*/}
                {/*}*/}
                {/*<div className="banner__foter">*/}
                {/*        <div className="cnt">*/}
                {/*            {translate.banner.text4[lang]}*/}
                {/*            {!user &&*/}
                {/*            <a href={'https://dobro.ru/vacancy/10028168?authorized=true'}*/}
                {/*               className={'banner__btn'}*/}
                {/*            >*/}
                {/*                {translate.banner.btn[lang]}*/}
                {/*            </a>*/}
                {/*            }*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }
}