import React from "react";
import Moment from "react-moment";
import ListEl from "../programs/_list-el";

export default class TimeTable extends React.Component{
    render() {
        const {userEvents, translate, lang} = this.props
        return(
            <div className={'timeTable'}>
                <div className="cnt">
                    <div className="timeTable__head">
                        {translate.profile.schedule[lang]}
                    </div>
                    <div className="timeTable__days">
                        {Object.keys(userEvents).length && Object.keys(userEvents).map(eventKey => {
                            return (
                                <div
                                    key={eventKey + +new Date()}
                                    className="day__el"
                                >
                                    <div className="day__head">
                                        <Moment locale={lang} format={'DD MMMM'}>{eventKey}</Moment>
                                    </div>
                                    {Object.keys(userEvents[eventKey]).map(nameKey => {

                                        return (
                                            <div
                                                key={nameKey + +new Date()}
                                                style={{marginTop: 20}}
                                            >
                                                <div className="day__name">
                                                    {nameKey}
                                                </div>
                                                <div className="day__list">
                                                    {userEvents[eventKey][nameKey].map(listEl => {
                                                        return (
                                                            <ListEl
                                                                day={{date: eventKey}}
                                                                event={listEl}

                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                        
                    </div>
                </div>
            </div>
        )
    }
}