import React from "react";
import ava from 'assets/image/ava.png'
import Api from "../../Service/Api";
import ImageUploader from "react-images-upload";


export default class UserData extends React.Component {
    Api = new Api()

    state = {
        pictures: false,
    }

    componentDidMount() {
        this.onDrop = this.onDrop.bind(this);
    }

    onDrop = (pictureFiles, pictureDataURLs) => {
        console.log(123, pictureFiles, pictureDataURLs)

        this.setState({
            pictures: pictureDataURLs
        }, () => {
            this.Api.setUserPhoto(pictureDataURLs)
                .then((res) => {
                    console.log(res)
                })
        });
    }

    logout = () => {
        const {getUserData} = this.props
        this.Api.logout()
            .then(getUserData);
    }

    sumPoint = () => {
        const {userPoint} = this.props
        let point = 0;
        if (userPoint) {
            userPoint.map(pointEl => {
                return point = point + pointEl.points;
            })
        }

        return point
    }

    render() {
        const {user, separatedEventsCount} = this.props
        const {pictures} = this.state
        return (
            <div className="userData">
                <div className="info">
                    <div className="cnt">
                        <div className="name">
                            <div className="left">
                                {user.name} {user.surname}
                                <span
                                    onClick={this.logout}
                                >
                                    (Выход)
                                </span>
                            </div>
                            <div className="right">
                                <div className="ava">

                                    <img src={pictures ? pictures : (user.image || ava)} alt="" className={'ava__img'}/>
                                    <ImageUploader
                                        withIcon={false}
                                        withPreview={false}
                                        buttonText="Выберите файл"
                                        withLabel={false}
                                        onChange={this.onDrop}
                                        imgExtension={[".jpg", ".png"]}
                                        maxFileSize={5242880}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="level">
                            <div className="level__text">
                                <span>Уровень 1</span>
                                <span>{this.sumPoint()} баллов</span>
                            </div>
                            <div className="level__line">
                                <div className="level__line-done" style={{width: '100%'}}/>
                            </div>
                        </div>

                    </div>
                </div>
                {separatedEventsCount &&
                <div className="events">
                    <div className="cnt">
                        <div className="events__head">
                            Мероприятия
                        </div>
                        <div className="events__flex">
                            <div className="events__flex-el">
                                <div className="name">Пройдено</div>
                                <div className="count">{separatedEventsCount.passed}</div>
                            </div>
                            <div className="events__flex-el">
                                <div className="name">В планах</div>
                                <div className="count">{separatedEventsCount.future}</div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}