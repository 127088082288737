import React from 'react'
import Api from 'Service/Api'
import DobroTemplate from "./dobro";
import EducationTemplate from "./education";


export default class ForumDayEvents extends React.Component {
    Api = new Api()

    state = {
        user: undefined,
        forumData: undefined,
        showAllProjects: false,
        forumId: 5
    }

    componentDidMount() {
        this.getUserData();
        this.getForumData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.forumId !== this.state.forumId) {
            this.getForumData()
        }
        if(this.state.forumData !== undefined){
            this.hashHandler()
        }
    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }

    setShowAllProjects = (showAllProjects) => {
        this.setState({showAllProjects})
    }

    getForumData = () => {
        this.Api.getForum(this.state.forumId)
            .then(forumData => {
                this.setState({forumData}, () => {
                })
            })
            .catch(e => console.log('error', e))
    }

    setForumId = (forumId) => {
        this.setState({forumId})
    }

    hashHandler = () => {
        let hash = window.location.hash
        console.log(hash.length)
        if(hash.length !== 0){
            let target = document.querySelector(hash)
            target.scrollIntoView({behavior: "smooth"})
        }
    }


    render() {
        const {user, forumData, showAllProjects} = this.state;
        //const forumId = this.props.match.params.id


        return <EducationTemplate
            user={user}
            forumData={forumData}
            showAllProjects={showAllProjects}
            forumId={this.state.forumId}
            getUserData={this.getUserData}
            getForumData={this.getForumData}
            setShowAllProjects={this.setShowAllProjects}
            setForumId={this.setForumId}
        />
    }
}
