import React from "react";
import moment from "moment";
import Api from "../../Service/Api";
import List from "./_list";
import translate from "../../Page/forumDayEvents/_translate";


export default class ListEl extends React.Component {

    Api = new Api();

    getClassList = () => {
        const {day, event, serverTime, timeZone, showList} = this.props
        let serverTimeMoment = moment(serverTime);
        let startVideoMomemnt = moment(day.date + ' ' + event.hour_start).subtract('30', 'minutes');
        let startMomemnt = moment(day.date + ' ' + event.hour_start);
        let EndMomemnt = moment(day.date + ' ' + event.hour_end);
        let diffVideo= serverTimeMoment.diff(startVideoMomemnt)
        let diffStart = serverTimeMoment.diff(startMomemnt)
        let diffEnd = serverTimeMoment.diff(EndMomemnt)
        let list_el_class = ''
        if (diffStart > 0 && diffEnd > 0) {
            list_el_class = 'list_el-end'
        } else if (diffStart > 0 && diffEnd < 0) {
            list_el_class = 'list_el-now'
        } else {
            if (event.track === 1) {
                list_el_class = 'list_el-red'
            }
            if (event.track === 2) {
                list_el_class = 'list_el-blue'
            }
            if (event.track === 3) {
                list_el_class = 'list_el-green'
            }
        }
        return list_el_class;
    }

    showSave = () => {
        const {user, event, userEvents} = this.props
        if (userEvents && userEvents.length) {
            return userEvents.map((userEvent) => {
                if (userEvent.event_id === event.id) {
                    return false
                } else {
                    return true
                }
            })
        }
        if (!user) {
            return [false]
        } else {
            return [true]
        }
    }

    saveEvent = () => {
        const {event, getForumData} = this.props;
        this.Api.saveEvent(event.id)
            .then((res) => {
                if (res) {
                    getForumData()
                }
            })
    }

    render() {
        const {event, speakers, serverTime, day, showPopapChange, showList, forum_id, translate, lang, user} = this.props
        console.log(speakers)

        let serverTimeMoment = moment(serverTime);
        let startVideoMomemnt = moment(day.date + ' ' + event.hour_start).subtract('30', 'minutes');
        // let diffVideo= serverTimeMoment.diff(startVideoMomemnt)
        let diffVideo= 1


        let list_el_class = this.getClassList();
        let showSave = this.showSave();


        return (
            <div className={"list_el " + list_el_class + (showList ? ' show' : ' hide')}>

                <div className="left">

                    <div className="time">{`${event.hour_start} - ${event.hour_end} `}</div>

                    {/*<div className="speakers">
                        {speakers && speakers.map((speaker, index) => {
                            return (
                                speaker.surname + ' ' + speaker.name + (index + 1 < speakers.length ? ', \n' : '')
                            )
                        })}
                    </div>*/}
                </div>

                <div className={"element "  + (!user && 'white')}>
                    {event.event_type != 4 ?
                        diffVideo > 0  ?
                            <a href={user ? ('/streamer/' + event.id) : ('/login')} className="name">{event.name}</a>
                            :
                            <div
                                onClick={showPopapChange}
                                className="name"
                            >{event.name}</div>
                        :
                        <div
                            className="name"
                        >{event.name}</div>
                    }
                    {/*<div className="info">Посмотреть записи</div>*/}

                    {list_el_class === 'list_el-end'
                        ?
                        event.event_type != 4 ?
                            <a href={user ? ('/streamer/' + event.id) : ('/login')}>
                                <div className="icon">
                                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.3382 7.69214L4.52572 0.410684C3.67111 -0.11545 2.63787 -0.137325 1.76173 0.352168C0.885597 0.841582 0.362549 1.73303 0.362549 2.73658V17.2332C0.362549 18.7506 1.58521 19.9917 3.08794 19.9999C3.09205 19.9999 3.09615 20 3.10017 20C3.56978 19.9999 4.05916 19.8527 4.51623 19.5738C4.88392 19.3495 5.00014 18.8696 4.7758 18.5019C4.55146 18.1342 4.07146 18.018 3.70388 18.2423C3.49009 18.3727 3.2813 18.4402 3.09634 18.4402C2.52935 18.4371 1.92228 17.9514 1.92228 17.2332V2.73662C1.92228 2.30611 2.14665 1.92381 2.52244 1.71385C2.89826 1.50389 3.34142 1.51326 3.70756 1.73869L15.5201 9.02014C15.8752 9.23874 16.0783 9.60323 16.0775 10.0202C16.0766 10.4371 15.872 10.8008 15.515 11.0186L6.97444 16.2478C6.60709 16.4728 6.49162 16.9529 6.71655 17.3202C6.94143 17.6875 7.42155 17.803 7.78889 17.5781L16.3284 12.3495C17.146 11.8507 17.6353 10.9812 17.6373 10.0233C17.6392 9.06557 17.1534 8.19401 16.3382 7.69214Z"
                                            fill="black"/>
                                    </svg>
                                </div>
                            </a>
                            :
                            ''

                        :
                        (list_el_class === 'list_el-now'
                                ?
                                event.event_type != 4 ?
                                    <a href={user ? ('/streamer/' + event.id) : ('/login')}>
                                        <div className="icon">
                                            <svg width="31" height="31" viewBox="0 0 31 31" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M29.9732 15.1706C28.8338 12.2233 26.8557 9.67453 24.2835 7.83921C21.7113 6.00388 18.6577 4.96249 15.5001 4.84375C12.3425 4.96249 9.28883 6.00388 6.71662 7.83921C4.1444 9.67453 2.16636 12.2233 1.02695 15.1706C0.950001 15.3835 0.950001 15.6165 1.02695 15.8294C2.16636 18.7767 4.1444 21.3255 6.71662 23.1608C9.28883 24.9961 12.3425 26.0375 15.5001 26.1562C18.6577 26.0375 21.7113 24.9961 24.2835 23.1608C26.8557 21.3255 28.8338 18.7767 29.9732 15.8294C30.0502 15.6165 30.0502 15.3835 29.9732 15.1706ZM15.5001 24.2188C10.3657 24.2188 4.9407 20.4116 2.97414 15.5C4.9407 10.5884 10.3657 6.78125 15.5001 6.78125C20.6345 6.78125 26.0595 10.5884 28.026 15.5C26.0595 20.4116 20.6345 24.2188 15.5001 24.2188Z"
                                                    fill="#FF6700"/>
                                                <path
                                                    d="M15.5 9.6875C14.3504 9.6875 13.2266 10.0284 12.2708 10.6671C11.3149 11.3058 10.5699 12.2136 10.13 13.2757C9.69002 14.3377 9.57491 15.5064 9.79919 16.634C10.0235 17.7615 10.5771 18.7972 11.3899 19.6101C12.2028 20.423 13.2385 20.9765 14.366 21.2008C15.4936 21.4251 16.6623 21.31 17.7243 20.8701C18.7864 20.4301 19.6942 19.6851 20.3329 18.7293C20.9716 17.7734 21.3125 16.6496 21.3125 15.5C21.3125 13.9584 20.7001 12.48 19.6101 11.3899C18.52 10.2999 17.0416 9.6875 15.5 9.6875ZM15.5 19.375C14.7336 19.375 13.9844 19.1477 13.3472 18.7219C12.7099 18.2962 12.2133 17.691 11.92 16.9829C11.6267 16.2748 11.5499 15.4957 11.6995 14.744C11.849 13.9923 12.218 13.3019 12.76 12.76C13.3019 12.218 13.9924 11.849 14.744 11.6995C15.4957 11.5499 16.2748 11.6267 16.9829 11.92C17.691 12.2133 18.2962 12.7099 18.7219 13.3472C19.1477 13.9844 19.375 14.7336 19.375 15.5C19.375 16.5277 18.9667 17.5133 18.24 18.24C17.5133 18.9667 16.5277 19.375 15.5 19.375Z"
                                                    fill="#FF6700"/>
                                            </svg>
                                        </div>
                                    </a>
                                    :
                                    ''
                                :
                                (showSave.indexOf(false) === -1 ?
                                        <div
                                            key={event.id + +new Date()}
                                            style={{display: 'flex', alignItems: 'center'}}
                                            onClick={this.saveEvent}

                                        >
                                            <div className="toltipe">
                                                {translate.program.add[lang]}
                                            </div>
                                            <div className={'icon saveEvent'}>

                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.0476 7.11902V12.5952L16.7352 15.3771L17.5238 14.0464L13.6905 11.7738V7.11902H12.0476Z"
                                                        fill="black"/>
                                                    <path
                                                        d="M13.1374 1.64282C7.68857 1.64282 3.28571 6.05661 3.28571 11.5H0L4.26594 15.7659L4.34259 15.9247L8.76192 11.5H5.47621C5.47621 7.26689 8.90978 3.83332 13.1429 3.83332C17.3759 3.83332 20.8095 7.26689 20.8095 11.5C20.8095 15.733 17.3759 19.1666 13.1429 19.1666C11.0236 19.1666 9.11236 18.3014 7.72692 16.9159L6.17714 18.4657C7.95692 20.2509 10.4157 21.3571 13.1374 21.3571C18.5862 21.3571 23 16.9433 23 11.5C23 6.05661 18.5862 1.64282 13.1374 1.64282Z"
                                                        fill="black"/>
                                                </svg>
                                            </div>
                                        </div>
                                        :
                                        <div className={'icon empty'}></div>
                                )

                        )

                    }
                </div>

            </div>

        )
    }
}
