import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import LinkMenu from "./link"
import { HashLink } from 'react-router-hash-link';

class menuScroll extends React.Component {

    state = {
        scrollTop: window.pageYOffset,
        navListShow: true
    }

    //Отлов события scroll
    handlerScroll = () => {
        this.setState({scrollTop: window.pageYOffset})
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handlerScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handlerScroll);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Check if the suplied props is changed
        if(prevProps.navListShow !== this.props.navListShow) {
            // run the function with the suplied new property
            this.changeNavList(this.props.navListShow);
        }
    }

    changeNavList = () => {
        if(window.screen.width <= 1050){
            this.setState({navListShow: !this.state.navListShow})
        }
    }

    // onRender = () => {
    //     console.log(111, 'doit')
    //     window.location.reload()
    // }

    render() {
        const {navListShow} = this.state
        const {className, forumId} = this.props
        return (
            <div className={"navigation__list " + (navListShow ? 'show' : '')} onClick={this.changeNavList}>
                {/*<Link*/}
                {/*    activeClass="active"*/}
                {/*    to={'/#speaker__head'}*/}
                {/*    className="navigation__list-el"*/}
                {/*    spy={true}*/}
                {/*    smooth={true}*/}
                {/*    duration={500}*/}
                {/*>*/}
                {/*    Спикеры*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*    activeClass="active"*/}
                {/*    to={'/#programs__head'}*/}
                {/*    className="navigation__list-el"*/}
                {/*    spy={true}*/}
                {/*    smooth={true}*/}
                {/*    duration={500}*/}
                {/*>*/}
                {/*    Программа*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*    activeClass="active"*/}
                {/*    to={'/#organizers_head'}*/}
                {/*    className="navigation__list-el"*/}
                {/*    spy={true}*/}
                {/*    smooth={true}*/}
                {/*    duration={500}*/}
                {/*>*/}
                {/*    Организаторы*/}
                {/*</Link>*/}
                {/*<Link*/}
                {/*    activeClass="active"*/}
                {/*    to={'/#partners_head'}*/}
                {/*    className="navigation__list-el"*/}
                {/*    spy={true}*/}
                {/*    smooth={true}*/}
                {/*    duration={500}*/}
                {/*>*/}
                {/*    Партнеры*/}
                {/*</Link>*/}
                <HashLink smooth to="/#speaker__head" className="navigation__list-el">
                    Спикеры
                </HashLink>
                <HashLink smooth to="/#programs__head" className="navigation__list-el">
                    Программа
                </HashLink>
                <HashLink smooth to="/#organizers_head" className="navigation__list-el">
                    Организаторы
                </HashLink>
                <HashLink smooth to="/#partners_head" className="navigation__list-el">
                    Партнеры
                </HashLink>


            </div>
        )
    }
}

export default menuScroll