import React from "react";
import List from './_list'
import Popap from 'Components/popap'
import translate from "../../Page/forumDayEvents/_translate";


export default class Programs extends React.Component {
    state = {
        showPopap: false
    }

    showPopapChange = () => {
        this.setState({showPopap: !this.state.showPopap})
    }

    render() {
        const {user, forumDays, forumDaysEvent, forumDaysEventSpeakers, serverTime, userEvents, getForumData, timeZone, forum_id, className, translate, lang} = this.props
        const {showPopap} = this.state
        return (
            <div className={"programs " + className} id="programs">
                <div className="alt-programs__head" id="programs__head">
                    {translate.program.althead[lang]}

                </div>
                <div className="cnt">

                    {forumDays.map((day, index) => {
                        if(forumDaysEvent[day.id] && day.hidden === 0) {
                            return (
                                <List
                                    forum_id={forum_id}
                                    key={day.id + +new Date()}
                                    user={user}
                                    timeZone={timeZone}
                                    day={day}
                                    index={index + 1}
                                    serverTime={serverTime}
                                    forumDaysEvent={forumDaysEvent[day.id]}
                                    forumDaysEventSpeakers={forumDaysEventSpeakers ? forumDaysEventSpeakers[day.id] : null}
                                    userEvents={userEvents}
                                    getForumData={getForumData}
                                    showPopapChange={this.showPopapChange}
                                    translate={translate}
                                    lang={lang}
                                    dayName={day.name.substring(5, 6)}
                                />
                            )
                        }else{
                            return <div/>
                        }
                    })}

                    {showPopap &&
                    <Popap
                        showPopapChange={this.showPopapChange}
                        text={'Кабинет мероприятия будет доступен за 30 минут до начала'}
                    />
                    }
                </div>
            </div>
        )
    }
}