import React, {useEffect} from "react";
import Header from "../../pageComponents/header";
import Speaker from "../../pageComponents/speaker";
import Programs from "../../pageComponents/programs";
import Organizers from "../../pageComponents/organizers";
import Partners from "../../pageComponents/partners";
import Footer from "../../pageComponents/footer";
import translate from "./_translate";
import {useCookies} from "react-cookie";
import Targets from "../../pageComponents/targets";
import ToPageTop from "../../pageComponents/toPageTop";
import Slider from "../../pageComponents/slider";
import Api from "../../Service/Api";
import { useHistory } from "react-router-dom";

const EducationTemplate = ({user, forumData, forumId, getUserData, getForumData, setForumId}) => {
    const api = new Api()
  const [cookies] = useCookies(['lang']);
  let lang = cookies.lang || 'ru';
  const descriptionName = document.querySelector("meta[name='description']");
  descriptionName.setAttribute("content", "Окружной образовательный форум");
    const history = useHistory()


  useEffect(()=>{
      vkHandler()
  },[])

    const vkHandler = () => {
        let search = window.location.search
        let formData = new FormData()
        formData.append('userData', search)
        if(search.length !== 0 && search.includes('payload') === true){
            api.checkUser(formData)
                .then(res => {
                    history.push('/')
                    window.location.reload()
                    //console.log(res)
                })
                .catch(e => console.log('error: ', e))
        }
    }



  return (
    <div className="forumDatEvents__page page">
        <ToPageTop/>
        <Header className={'header__ed'} user={user} getUserData={getUserData} forumId={forumId}/>
        <Slider setForumId={setForumId} user={user}/>
        <Targets/>
        {forumData && forumData.forumSpeaker ?
            <Speaker
                speakers={forumData.forumSpeaker}
                className={'vmesteSpeaker'}
                forum_id={forumId}
            /> :
            <a className="partners partners__empty" href={'/login'}>
                <div className="cnt">
                    <div className="partners__head" id={'partners_head'}>
                        Спикеры
                    </div>
                </div>
            </a>
        }
        {forumData && forumData.forumDays ?
            <Programs
                forum_id={forumId}
                user={user}
                serverTime={forumData.server.date}
                timeZone={forumData.forum.timeZone}
                forumDays={forumData.forumDays}
                forumDaysEvent={forumData.forumDaysEvent}
                forumDaysEventSpeakers={forumData.forumDaysEventSpeakers}
                userEvents={forumData.userEvents}
                getForumData={getForumData}
                translate={translate}
                lang={lang}
            /> :
            <a className="partners partners__empty" href={'/login'}>
                <div className="cnt">
                    <div className="partners__head" id={'partners_head'}>
                        Программа
                    </div>
                </div>
            </a>
        }
        <Organizers forum_id={forumId}/>
        <Partners forum_id={forumId}/>
        <Footer className="footer__ed" forumId={forumId} user={user} getUserData={getUserData}/>
    </div>
  )
};

export default EducationTemplate