import React from "react";
import img1 from 'assets/image/block-img.png';


export default class Targets extends React.Component {
    render() {
        return (
            <div className="targets">
                <div className="targets__header">
                    <div className="cnt">
                        <div className="targets__title">
                            <p>О форумах</p>
                            <div className="targets__line"></div>
                            <p className="targets__subtitle">Окружные патриотические форумы - это ключевые образовательные мероприятия для специалистов сферы патриотического воспитания со всей страны. Окружные форумы служат площадкой для открытого общения представителей региональных органов исполнительной и региональной власти, руководителей и сотрудников центров патриотического вопитания, лидеров общественного мнения и специалистов, работающих в области патриотического воспитания молодежи.</p>
                        </div>
                    </div>
                </div>
                <div className="targets__body">
                    <div className="cnt">
                        <div className="targets__list-title">
                            <p>Задачи форумов</p>
                            <div className="targets__line"></div>
                        </div>
                        {/*<ol className="targets__list">*/}
                        {/*    <li className="targets__item">*/}
                        {/*        <span className="targets__number">1</span>*/}
                        {/*        <span className="targets__text">*/}
                        {/*            Формирование профессиональных компетенций и прикладных навыков в сфере патриотического воспитания*/}
                        {/*        </span>*/}
                        {/*    </li>*/}
                        {/*    <li className="targets__item">*/}
                        {/*        <span className="targets__number">2</span>*/}
                        {/*        <span className="targets__text">Развитие научного, делового сотрудничества и методического сопровождения системы патриотического воспитания граждан</span>*/}
                        {/*    </li>*/}
                        {/*    <li className="targets__item">*/}
                        {/*        <span className="targets__number">3</span>*/}
                        {/*        <span className="targets__text">Развитие и поддержка сообщества специалистов в сфере гражданскоого-патриотического воспитания</span>*/}
                        {/*    </li>*/}
                        {/*    <li className="targets__item">*/}
                        {/*        <span className="targets__number">4</span>*/}
                        {/*        <span className="targets__text">Объединение усилий, направленных на гражданское, нравственное и патриотическое воспитание подростающего поколения</span>*/}
                        {/*    </li>*/}
                        {/*</ol>*/}
                        <div className="targets__container">
                            <div className="targets__item-block">
                                <div className="targets__left-side">
                                    <svg width="80%" id="_Слой_2" data-name="Слой 2" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 300.98 267.94">
                                        <g id="_Слой_1-2" data-name="Слой 1">
                                            <g>
                                                <path fill="#ca2026"
                                                      d="M300.61,183.32c-4.38-61.62-58.36-115.67-106.7-152.94,0,0-.02,0-.05,.02-.21-.18-.42-.37-.66-.55C163.91,7.35,152.11,.86,150.49,0c-1.62,.86-13.42,7.35-42.71,29.85-.24,.19-.46,.37-.66,.55-.03-.02-.05-.02-.05-.02C58.74,67.65,4.75,121.7,.38,183.32c-3.2,45.13,14.19,53.61,45.2,84.62,32-41.72,79.06-78.15,104.91-96.74,25.85,18.59,72.91,55.02,104.91,96.74,31.01-31.01,48.4-39.48,45.2-84.62Zm-54.13,14.19c-2.61-2.61-86.45-71.04-95.98-71.98-9.53,.94-93.37,69.37-95.98,71.98-2.63,2.63-47.88-42.53,95.98-140.72,143.86,98.19,98.61,143.34,95.98,140.72Z"/>
                                                <path fill="#FFF"
                                                      d="M247.68,198.47s-26.88-23.07-61.78-50.84h0c-10.51-8.94-31.01-22.08-35.21-22.08-8.56,0-93.55,69.38-96.18,72-2.63,2.63-47.92-42.57,96.18-140.86,0,0-.28-.2-.78-.54,3.84,2.5,7.75,5.12,11.63,7.81,2.02,1.4,3.9,2.72,5.65,3.95,2.48,1.52,4.89,3.1,7.19,4.77,1.98,1.56,4.05,3.22,6.18,4.94,6.17,4.67,8.54,6.86,9.43,7.8,28.36,23.98,61.08,57.47,64.24,83.22,4.35,21.55-6.55,29.81-6.55,29.81h0Z"/>
                                                <path fill="#3e65af"
                                                      d="M99.71,128.19c-44.67,34.69-45.73,70.43-45.73,70.43,0,0,87.25-70.43,96.7-72.53,6.83-1.52,97.23,72.53,97.23,72.53-5.99-29.94-18.34-46.33-45.73-70.43-20.31-17.87-36.62-26.63-51.51-37.84-15.24,11.04-19.41,13.33-50.98,37.84Z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="targets__right-side">
                                    Формирование профессиональных компетенций и прикладных навыков в сфере патриотического воспитания
                                </div>
                            </div>
                            <div className="targets__item-block">
                                <div className="targets__left-side">
                                    <svg width="80%" id="_Слой_2" data-name="Слой 2" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 300.98 267.94">
                                        <g id="_Слой_1-2" data-name="Слой 1">
                                            <g>
                                                <path fill="#ca2026"
                                                      d="M300.61,183.32c-4.38-61.62-58.36-115.67-106.7-152.94,0,0-.02,0-.05,.02-.21-.18-.42-.37-.66-.55C163.91,7.35,152.11,.86,150.49,0c-1.62,.86-13.42,7.35-42.71,29.85-.24,.19-.46,.37-.66,.55-.03-.02-.05-.02-.05-.02C58.74,67.65,4.75,121.7,.38,183.32c-3.2,45.13,14.19,53.61,45.2,84.62,32-41.72,79.06-78.15,104.91-96.74,25.85,18.59,72.91,55.02,104.91,96.74,31.01-31.01,48.4-39.48,45.2-84.62Zm-54.13,14.19c-2.61-2.61-86.45-71.04-95.98-71.98-9.53,.94-93.37,69.37-95.98,71.98-2.63,2.63-47.88-42.53,95.98-140.72,143.86,98.19,98.61,143.34,95.98,140.72Z"/>
                                                <path fill="#FFF"
                                                      d="M247.68,198.47s-26.88-23.07-61.78-50.84h0c-10.51-8.94-31.01-22.08-35.21-22.08-8.56,0-93.55,69.38-96.18,72-2.63,2.63-47.92-42.57,96.18-140.86,0,0-.28-.2-.78-.54,3.84,2.5,7.75,5.12,11.63,7.81,2.02,1.4,3.9,2.72,5.65,3.95,2.48,1.52,4.89,3.1,7.19,4.77,1.98,1.56,4.05,3.22,6.18,4.94,6.17,4.67,8.54,6.86,9.43,7.8,28.36,23.98,61.08,57.47,64.24,83.22,4.35,21.55-6.55,29.81-6.55,29.81h0Z"/>
                                                <path fill="#3e65af"
                                                      d="M99.71,128.19c-44.67,34.69-45.73,70.43-45.73,70.43,0,0,87.25-70.43,96.7-72.53,6.83-1.52,97.23,72.53,97.23,72.53-5.99-29.94-18.34-46.33-45.73-70.43-20.31-17.87-36.62-26.63-51.51-37.84-15.24,11.04-19.41,13.33-50.98,37.84Z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="targets__right-side">
                                    Развитие научного, делового сотрудничества и методического сопровождения системы патриотического воспитания граждан
                                </div>
                            </div>
                            <div className="targets__item-block">
                                <div className="targets__left-side">
                                    <svg width="80%" id="_Слой_2" data-name="Слой 2" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 300.98 267.94">
                                        <g id="_Слой_1-2" data-name="Слой 1">
                                            <g>
                                                <path fill="#ca2026"
                                                      d="M300.61,183.32c-4.38-61.62-58.36-115.67-106.7-152.94,0,0-.02,0-.05,.02-.21-.18-.42-.37-.66-.55C163.91,7.35,152.11,.86,150.49,0c-1.62,.86-13.42,7.35-42.71,29.85-.24,.19-.46,.37-.66,.55-.03-.02-.05-.02-.05-.02C58.74,67.65,4.75,121.7,.38,183.32c-3.2,45.13,14.19,53.61,45.2,84.62,32-41.72,79.06-78.15,104.91-96.74,25.85,18.59,72.91,55.02,104.91,96.74,31.01-31.01,48.4-39.48,45.2-84.62Zm-54.13,14.19c-2.61-2.61-86.45-71.04-95.98-71.98-9.53,.94-93.37,69.37-95.98,71.98-2.63,2.63-47.88-42.53,95.98-140.72,143.86,98.19,98.61,143.34,95.98,140.72Z"/>
                                                <path fill="#FFF"
                                                      d="M247.68,198.47s-26.88-23.07-61.78-50.84h0c-10.51-8.94-31.01-22.08-35.21-22.08-8.56,0-93.55,69.38-96.18,72-2.63,2.63-47.92-42.57,96.18-140.86,0,0-.28-.2-.78-.54,3.84,2.5,7.75,5.12,11.63,7.81,2.02,1.4,3.9,2.72,5.65,3.95,2.48,1.52,4.89,3.1,7.19,4.77,1.98,1.56,4.05,3.22,6.18,4.94,6.17,4.67,8.54,6.86,9.43,7.8,28.36,23.98,61.08,57.47,64.24,83.22,4.35,21.55-6.55,29.81-6.55,29.81h0Z"/>
                                                <path fill="#3e65af"
                                                      d="M99.71,128.19c-44.67,34.69-45.73,70.43-45.73,70.43,0,0,87.25-70.43,96.7-72.53,6.83-1.52,97.23,72.53,97.23,72.53-5.99-29.94-18.34-46.33-45.73-70.43-20.31-17.87-36.62-26.63-51.51-37.84-15.24,11.04-19.41,13.33-50.98,37.84Z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="targets__right-side">
                                    Развитие и поддержка сообщества специалистов в сфере гражданского-патриотического воспитания
                                </div>
                            </div>
                            <div className="targets__item-block">
                                <div className="targets__left-side">
                                    <svg width="80%" id="_Слой_2" data-name="Слой 2" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 300.98 267.94">
                                        <g id="_Слой_1-2" data-name="Слой 1">
                                            <g>
                                                <path fill="#ca2026"
                                                      d="M300.61,183.32c-4.38-61.62-58.36-115.67-106.7-152.94,0,0-.02,0-.05,.02-.21-.18-.42-.37-.66-.55C163.91,7.35,152.11,.86,150.49,0c-1.62,.86-13.42,7.35-42.71,29.85-.24,.19-.46,.37-.66,.55-.03-.02-.05-.02-.05-.02C58.74,67.65,4.75,121.7,.38,183.32c-3.2,45.13,14.19,53.61,45.2,84.62,32-41.72,79.06-78.15,104.91-96.74,25.85,18.59,72.91,55.02,104.91,96.74,31.01-31.01,48.4-39.48,45.2-84.62Zm-54.13,14.19c-2.61-2.61-86.45-71.04-95.98-71.98-9.53,.94-93.37,69.37-95.98,71.98-2.63,2.63-47.88-42.53,95.98-140.72,143.86,98.19,98.61,143.34,95.98,140.72Z"/>
                                                <path fill="#FFF"
                                                      d="M247.68,198.47s-26.88-23.07-61.78-50.84h0c-10.51-8.94-31.01-22.08-35.21-22.08-8.56,0-93.55,69.38-96.18,72-2.63,2.63-47.92-42.57,96.18-140.86,0,0-.28-.2-.78-.54,3.84,2.5,7.75,5.12,11.63,7.81,2.02,1.4,3.9,2.72,5.65,3.95,2.48,1.52,4.89,3.1,7.19,4.77,1.98,1.56,4.05,3.22,6.18,4.94,6.17,4.67,8.54,6.86,9.43,7.8,28.36,23.98,61.08,57.47,64.24,83.22,4.35,21.55-6.55,29.81-6.55,29.81h0Z"/>
                                                <path fill="#3e65af"
                                                      d="M99.71,128.19c-44.67,34.69-45.73,70.43-45.73,70.43,0,0,87.25-70.43,96.7-72.53,6.83-1.52,97.23,72.53,97.23,72.53-5.99-29.94-18.34-46.33-45.73-70.43-20.31-17.87-36.62-26.63-51.51-37.84-15.24,11.04-19.41,13.33-50.98,37.84Z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="targets__right-side">
                                    Объединение усилий, направленных на гражданское, нравственное и патриотическое воспитание подрастающего поколения
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}